import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				О нас | Откройте для себя стадион Шахтёр
			</title>
			<meta name={"description"} content={"Возвысьте свою игру"} />
			<meta property={"og:title"} content={"О нас | Откройте для себя стадион Шахтёр"} />
			<meta property={"og:description"} content={"Возвысьте свою игру"} />
			<meta property={"og:image"} content={"https://nexavibex.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://nexavibex.com/img/564571134.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://nexavibex.com/img/564571134.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://nexavibex.com/img/564571134.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://nexavibex.com/img/564571134.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://nexavibex.com/img/564571134.png"} />
			<meta name={"msapplication-TileImage"} content={"https://nexavibex.com/img/564571134.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="150px 0 150px 0" background="rgba(0, 0, 0, 0) url(https://nexavibex.com/img/4.jpg) 50% 50% /cover repeat scroll padding-box" sm-padding="80px 0 80px 0" quarkly-title="Product-7">
			<Box min-width="100px" min-height="100px" display="flex" justify-content="flex-end">
				<Box
					min-width="100px"
					min-height="100px"
					width="400px"
					background="#ffffff"
					padding="50px 35px 50px 35px"
					display="flex"
					align-items="center"
					flex-direction="column"
					md-padding="35px 35px 35px 35px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 600 32px/1.2 --fontFamily-sans" text-align="center">
					О нас
					</Text>
					<Text margin="0px 0px 35px 0px" text-align="center" font="normal 400 18px/1.5 --fontFamily-sansHelvetica">
					Погрузитесь в мир стадиона Шахтёр, где каждая игра становится незабываемым событием. Познакомьтесь с нашей историей и узнайте, почему Шахтёр Stadium является предпочтительным выбором для любителей футбола.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-5">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
			Наша история
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
			Стадион Шахтёр был создан с целью обеспечить превосходную футбольную среду для игроков всех уровней. Наши основатели осознали необходимость создания специализированного места, где любовь к футболу могла бы процветать. С самого начала наша цель заключалась в предоставлении высококачественных услуг, чтобы каждый матч, проведенный здесь, был незабываемым.
			</Text>
			<Box min-width="100px" min-height="100px" display="grid" grid-gap="50px 50px">
				<Box min-width="100px" min-height="100px">
					
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					За годы своего существования Шахтёр Stadium стал маяком для любителей футбола. Наши поля стали свидетелями бесчисленных матчей, тренировок и незабываемых моментов. Мы гордимся тем, что поддерживаем самые высокие стандарты, регулярно модернизируя их и тщательно ухаживая за ними, чтобы обеспечить наилучшие условия для игры.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Наши объекты
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Высококачественный газон: Наши поля оснащены газоном премиум-класса, который обеспечивает ровную, безопасную и приятную игровую поверхность. Оно разработано для снижения риска травм и повышения эффективности игры.
						<br />
						<br />
						Ухоженные поля: Наше стремление к совершенству отражается в первозданном состоянии наших полей. Регулярное обслуживание и уход гарантируют, что поля всегда готовы к игре.
						<br />
						<br />
						Современные удобства: Наслаждайтесь удобством чистых и комфортных раздевалок, просторной парковкой и легким доступом к закусочным. Все, что нужно для отличного футбола, находится здесь.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Присоединяйтесь к сообществу стадиона Шахтёр
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Станьте частью семьи Шахтёр Stadium и создайте свои собственные футбольные воспоминания вместе с нами. Неважно, опытный ли вы игрок или только начинаете, наши объекты созданы для того, чтобы предложить вам наилучший опыт. Мы приглашаем вас узнать, что делает Шахтёр Stadium особенным, и сделать его своим любимым футбольным местом.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-3">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="65%"
				align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				justify-content="center"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					height="auto"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://nexavibex.com/img/5.jpg"
						object-fit="cover"
						left={0}
						top={0}
						right={0}
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box
					transform="translateY(0px)"
					width="100%"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						bottom={0}
						src="https://nexavibex.com/img/6.jpg"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Забронируйте свой следующий матч на стадионе Шахтёр уже сегодня. Насладитесь футболом как никогда раньше и поймите, почему мы лучшие в своем деле. Ваше футбольное путешествие начинается здесь!
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});